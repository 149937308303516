/* eslint-disable prettier/prettier */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Button from "@material-ui/core/Button";

import GuestBlogForm from "../components/Form/guestBlogForm";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";

// import singaporeSmeBannerImg from "../images/v2/campaigns/singapore-sme-banner.png";
import singaporeSmeImg from "../images/v2/campaigns/blog-guest.png";
import tickMark from "../images/v2/GreenTick.png";
import tickMarkSmall from "../images/v2/GreenTickSmall.png";
import chooseBlogImg from "../images/v2/campaigns/choose-blog.png";
import uniqueContent from "../images/v2/campaigns/unique-content.svg";
import wordLimit from "../images/v2/campaigns/word-limit.svg";
import actionTakeaway from "../images/v2/campaigns/action-takeaway.svg";
import seoContent from "../images/v2/campaigns/seo-content.svg";
import visualElements from "../images/v2/campaigns/visual-element.svg";
import unaffiliatedSkill from "../images/v2/campaigns/unaffiliated-skill.svg";
import pepperCloudCRMHelpsBg from "../images/v2/campaigns/line-bg.svg";
import guideCircle from "../images/v2/campaigns/guide-circle.svg";
import guideDot from "../images/v2/campaigns/guide-dot.svg";
import pepperCloudCRMHelpsContentBg from "../images/v2/campaigns/crm-helps-content-bg.svg";
import guestPostGuidelinesPDF from "../images/guestPostGuidelines.pdf";
import editProcessBg from "../images/v2/campaigns/editProcess.png";

import formSuccessImg from "../images/v2/campaigns/form-success-img.svg";
import useWidth from "../hooks/useWidth";
import requestDemoBGImg from "../images/v2/campaigns/request-demo-bg.svg";
import WhiteButton from "../components/v2/WhightButton";
import IntegratedCRMBgImg from "../images/v2/home/integrated-crm-bg.svg";
import SEO from "../components/SEO";

const useStyles = makeStyles((theme) => ({
  marginAuto: {
    margin: "0 auto",
    color: "#000",
  },
  header: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.29)",
    padding: ".65rem 0 .25rem",
  },
  singaporeSmeBanner: {
    backgroundImage: "linear-gradient(to bottom, #1fa5c070, #ffffff)",
    padding: "82px 0 0",
    paddingTop: "160px",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 0 0",
    },
  },

  singaporeSmeFrom: {
    borderRadius: "10px",
    boxShadow: "0 2px 5px 0 #b7b7b7",
    maxWidth: "440px",
    backgroundSize: "430px 522px",
    backgroundColor: "#fff",
    position: "relative",
    zIndex: "1",
    margin: "0 auto",
  },
  singaporeSmeFromHeader: {
    background: "#2e3f4f",
    borderRadius: "10px 10px 0 0",
    padding: ".6rem .25rem",
    letterSpacing: ".32px",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  salesProcessCard: {
    border: "none",
    boxShadow: "none",
    // backgroundColor: "transparent",
    marginBottom: theme.spacing(4),
  },
  cardHeaderAvatar: {
    height: 100,
    width: 100,
    background: "#FFF",
    boxShadow: "1px 1px 7px 0 rgb(0 0 0 / 19%)",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -68,
  },
  colorChange: {
    color: "#ff7a59",
    fontSize: "30px",
    fontWeight: "700",
    letterSpacing: ".4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  cardHeader: {
    background: "#f2f7fd",
    marginLeft: 54,
    // [theme.breakpoints.down("sm")]: {
    // 	alignItems: "end !important",
    // },
  },
  IntegratedCRMBg: {
    background: `url(${IntegratedCRMBgImg}) center bottom no-repeat`,
    backgroundSize: "cover",
  },
  whitespaceNowrap: {
    whiteSpace: "nowrap",
  },
  pepperCloudCRMHelps: {
    [theme.breakpoints.up("md")]: {
      background: `url(${pepperCloudCRMHelpsBg}) no-repeat`,
      backgroundSize: "100%",
      backgroundPosition: "center",
    },
  },
  pepperCloudCRMHelpsSpan: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#ffffff",
      padding: "0 1rem",
    },
  },
  pepperCloudCRMHelpsContent: {
    background: `url(${pepperCloudCRMHelpsContentBg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  pepperCloudCRMHelpsContentListBox: {
    boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#ffffff",
    padding: "2rem 1rem",
  },
  pepperCloudCRMHelpsContentList: {
    listStyle: "none",
    padding: "0px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  pepperCloudCRMHelpsContentListItem: {
    display: "inline-block",
    paddingRight: "1rem",
    marginRight: "1rem",
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none !important",
    },
    "&:not(:last-of-type)": {
      borderRight: "1px solid rgba(112, 112, 112, .5)",
    },
  },
  pepperCloudCRMHelpsContentListItemSpan: {
    fontSize: "36px",
    color: "#088937",
    paddingRight: "1rem",
    lineHeight: "normal",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  blogCategory: {
    marginTop: "40px",
    "@media(max-width:1024px)": {
      marginTop: "24px",
    },
  },
  blogCategoryItem1: {
    backgroundColor: "#daffdd",
    border: "1px solid #199a10",
    borderRadius: "18px",
    padding: "16px 24px",
    boxShadow: "3px 3px 12px 0 #0000001a",
  },
  blogCategoryItem2: {
    backgroundColor: "#fff8d6",
    border: "1px solid #cab712",
    borderRadius: "18px",
    padding: "16px 24px",
    boxShadow: "3px 3px 12px 0 #0000001a",
  },
  blogCategoryItem3: {
    backgroundColor: "#ffe3e3",
    border: "1px solid #f56464",
    borderRadius: "18px",
    padding: "16px 24px",
    boxShadow: "3px 3px 12px 0 #0000001a",
  },
  blogCategoryItem4: {
    backgroundColor: "#d7e8fd",
    border: "1px solid #1c84d4",
    borderRadius: "18px",
    padding: "16px 24px",
    boxShadow: "3px 3px 12px 0 #0000001a",
  },
  blogCatagoryItem: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  blogCatagoryItemImg: {
    marginRight: "16px",
  },
  dashBorder: {
    "&:nth-of-type(1)": {
      borderRight: "2px dashed #0d6889",
      borderBottom: "2px dashed #0d6889",
    },
    "&:nth-of-type(2)": {
      borderRight: "2px dashed #0d6889",
      borderBottom: "2px dashed #0d6889",
      paddingLeft: "40px",
    },
    "&:nth-of-type(3)": {
      borderBottom: "2px dashed #0d6889",
      paddingLeft: "40px",
    },
    "&:nth-of-type(4)": {
      borderRight: "2px dashed #0d6889",
    },
    "&:nth-of-type(5)": {
      borderRight: "2px dashed #0d6889",
      paddingLeft: "40px",
    },
    "&:nth-of-type(6)": {
      paddingLeft: "40px",
    },

    "@media (max-width: 960px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:nth-of-type(1)": {
        borderRight: "0 dashed #0d6889",
        borderBottom: "0 dashed #0d6889",
      },
      "&:nth-of-type(2)": {
        borderRight: "0 dashed #0d6889",
        borderBottom: "0 dashed #0d6889",
        padding: "0",
      },
      "&:nth-of-type(3)": {
        borderBottom: "0 dashed #0d6889",
        padding: "0",
      },
      "&:nth-of-type(4)": {
        borderRight: "0 dashed #0d6889",
      },
      "&:nth-of-type(5)": {
        borderRight: "0 dashed #0d6889",
        padding: "0",
      },
      "&:nth-of-type(6)": {
        padding: "0",
      },
    },
    "@media (max-width:770px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "650px",
      width: "100%",
    },
  },
  writeToUsButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "85px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "65px",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "60px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "60px",
    },
  },
  promotionBox: {
    display: "flex",
    alignItems: "center",
  },
  headingPadding: {
    padding: "16px 16px 0",
  },
  promotionBoxContent: {
    fontSize: "18px",
    color: "#000",
    paddingLeft: "16px",
  },
  blogCategoryHeading: {
    margin: "0 0 8px",
    color: "#000",
    fontWeight: "600",
  },
  blogCathead: {
    marginBottom: "60px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "18px",
    },
    color: "#000",
  },
  blogCatGrid: {
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
  },
  blogCatagory: {
    maxWidth: "85%",
    padding: "32px 0",
    minHeight: "200px",
    [theme.breakpoints.down("md")]: {
      padding: "16px 0 0",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
      maxWidth: "auto",
    },
  },
  blogCatagoryHead: {
    margin: "0",
    fontWeight: "600",
    color: "#000",
  },
  blogCatagoryContent: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    color: "#000",
  },
  guestGuidlines: {
    background: "#f5fbfd",
    position: "relative",
    overflow: "hidden",
    paddingBottom: "40px",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },
  guestGuidCircle: {
    position: "absolute",
    top: "10%",
    right: "-130px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  guestGuidDots: {
    position: "absolute",
    top: "-40%",
    left: "-130px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  downloadBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "16px",
    },
    p: {
      margin: "0",
    },
  },
  downloadBtnInner: {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    color: "#1b42dd",
    fontSize: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  catBox: {
    display: "flex",
    alignItems: "flex-start",
  },
  catBoxContent: {
    margin: "0",
    paddingLeft: "14px",
    fontSize: "16px",
    textAlign: "left",
    color: "#000",
  },
  chatBoximg: {
    marginTop: "8px",
  },
  bannerHeader: {
    [theme.breakpoints.down("md")]: {
      lineHeight: "1",
    },
    color: "#000",
  },
  editorialProcess: {
    background: `url(${editProcessBg})`,
    backgroundSize: "cover",
    position: "relative",
    top: "-42px",
    paddingTop: "80px",
    "@media (min-width:1920px)": {
      top: "-100px",
    },
    "@media (max-width:1919px)": {
      top: "-80px",
    },
    "@media (max-width:1500px)": {
      top: "-60px",
    },
    "@media (max-width:1499px)": {
      top: "-60px",
    },
    [theme.breakpoints.down("md")]: {
      top: "-60px",
      paddingBottom: "28px",
    },
  },
  promotionBoxSection: {
    paddingTop: "0",
    [theme.breakpoints.down("md")]: {
      padding: "0 0 24px ",
    },
  },
  promotionGrid: {
    "&:nth-child(1)": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "&:nth-child(2)": {
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  catBoxImg: {
    position: "relative",
  },
  catBoxImgInner: {
    position: "absolute",
    top: "24px",
    right: "-15%",
    "@media (max-width:1500px)": {
      right: "-12%",
    },
    "@media (max-width:1400px)": {
      right: "-8%",
      height: "350px",
    },
    "@media (max-width:1380px)": {
      right: "-3%",
      height: "300px",
    },
    "@media (max-width:1280px)": {
      right: "0",
      height: "280px",
    },
    "@media (max-width:960px)": {
      position: "static",
      height: "auto",
      marginTop: "16px",
      width: "100%",
    },
  },
  catBoxIGrid: {
    alignItems: "flex-start",
  },
}));

const GuestBlog = () => {
  const [success, setSuccess] = React.useState(false);

  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = guestPostGuidelinesPDF;
    link.download = "Guest-Post-Guidelines.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSuccess = () => {
    setSuccess(true);
  };
  const classes = useStyles();

  const width = useWidth();
  return (
    <React.Fragment>
      {/* Header  */}
      <SEO
        description="Join the team of our guest writer & share your expertise on topics such as sales management, marketing, CRM, automation, startup & more, to empower our readers to boost sales"
        keywords=" Write for us,Guest blogging,CRM guest posts, sales management blog"
        title="Write for Us | Guest Post Submission | Pepper Cloud"
      />
      {/* Banner */}
      <Box className={classes.singaporeSmeBanner}>
        <Container>
          <Grid alignItems="flex-start" container justify="center">
            <Grid item md={7} sm={12}>
              <Box>
                <HeaderTypography
                  component="h2"
                  className={classes.bannerHeader}
                  fontSize={{ sm: 36, xs: 34 }[width] || 40}
                  lineHeight={{ sm: 2, xs: 2 }[width] || "normal"}
                  mt={4}
                  my={2}
                  overrideClassName
                >
                  Be our guest author
                </HeaderTypography>
                <ParagraphTypography
                  color="#000"
                  font-family="SourceSansPro"
                  fontSize={18}
                  fontWeight={400}
                  mb={3}
                  mt={0}
                >
                  Become a guest contributor and share your valuable insights
                  with a global audience. Write about sales management,
                  productivity, customer relationship management, and more.
                </ParagraphTypography>
                <Box
                  alt="B2B Guest blogging on sales automation, sales communication, and business growth"
                  title="B2B Guest blogging on sales automation, sales communication, and business growth"
                  component={"img"}
                  maxHeight="500px"
                  maxWidth="100%"
                  src={singaporeSmeImg}
                />
              </Box>
            </Grid>
            <Grid item md={5} sm={12}>
              <Box
                className={classes.singaporeSmeFromBox}
                px={1}
                textAlign="center"
              >
                <Box className={classes.singaporeSmeFrom}>
                  <HeaderTypography
                    className={classes.headingPadding}
                    color="#000"
                    component="h3"
                    fontSize="23px"
                    fontWeight={500}
                    m={0}
                    textAlign="left"
                  >
                    Write for Us
                  </HeaderTypography>
                  {success ? (
                    <Box p={8} textAlign="center">
                      <Box
                        alt="PepperCloud form success"
                        component={"img"}
                        mr={1}
                        src={formSuccessImg}
                        width="120px"
                      />
                      <HeaderTypography fontSize={18} my={2}>
                        Thank you for your interest!
                      </HeaderTypography>
                      <ParagraphTypography
                        color="#2e3f4f"
                        fontSize={16}
                        letterSpacing={0.56}
                        lineHeight={1.56}
                        m={0}
                      >
                        We have received your submission and we will respond to
                        you shortly.
                      </ParagraphTypography>
                    </Box>
                  ) : (
                    <GuestBlogForm
                      handleSuccess={handleSuccess}
                      width={width}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* Content*/}
      <Box pb={8} pt={{ sm: 8, xs: 8 }[width] || 15} textAlign="center">
        <Container>
          <Box className={classes.marginAuto} maxWidth={960}>
            <HeaderTypography
              fontSize={40}
              fontWeight={600}
              mb={3}
              mt={0}
              color="#000"
              component="h2"
            >
              Choose a blog category
            </HeaderTypography>
            <ParagraphTypography
              fontSize={18}
              font-family="SourceSansPro"
              color="#000"
            >
              Reach out to sales and marketing professionals, managers and
              leaders, and small to medium-sized business owners through your
              blog. Join us in building a trusted knowledge repository in the
              following categories.
            </ParagraphTypography>
          </Box>
          <Box className={classes.blogCategory}>
            <Grid
              alignItems="center"
              container
              justify="center"
              spacing={6}
              className={classes.catBoxIGrid}
            >
              <Grid item md={7} sm={12} xs={12}>
                <Grid
                  alignItems="center"
                  container
                  justify="center"
                  mb={2}
                  spacing={4}
                >
                  <Grid item md={6} sm={12} xs={12}>
                    <Box className={classes.blogCategoryItem1}>
                      <ParagraphTypography
                        alignItems="left"
                        className={classes.blogCategoryHeading}
                        fontSize="22px"
                        textAlign="left"
                      >
                        Sales Management
                      </ParagraphTypography>
                      <Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Sales productivity
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Sales skills
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Sales pipeline
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Sales KPIs
                            </ParagraphTypography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box className={classes.blogCategoryItem2}>
                      <ParagraphTypography
                        alignItems="left"
                        className={classes.blogCategoryHeading}
                        fontSize="22px"
                        textAlign="left"
                      >
                        Customer Relationship
                      </ParagraphTypography>
                      <Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Customer relationship management
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              CRM software
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              CRM integration
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              WhatsApp CRM
                            </ParagraphTypography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box className={classes.blogCategoryItem3}>
                      {" "}
                      <ParagraphTypography
                        alignItems="left"
                        className={classes.blogCategoryHeading}
                        fontSize="22px"
                        textAlign="left"
                      >
                        Automation
                      </ParagraphTypography>
                      <Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Sales automation
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Marketing automation
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Workflow automation
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              AI automation
                            </ParagraphTypography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box className={classes.blogCategoryItem4}>
                      {" "}
                      <ParagraphTypography
                        alignItems="left"
                        className={classes.blogCategoryHeading}
                        fontSize="22px"
                        textAlign="left"
                      >
                        Business Communication
                      </ParagraphTypography>
                      <Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Business development
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Conversational sales
                            </ParagraphTypography>
                          </Box>
                        </Box>
                        <Box className={classes.catBox}>
                          <Box
                            className={classes.chatBoximg}
                            component={"img"}
                            height="8px"
                            src={tickMarkSmall}
                          />
                          <Box>
                            <ParagraphTypography
                              className={classes.catBoxContent}
                            >
                              Sales and marketing through social messaging
                              platforms
                            </ParagraphTypography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={5} sm={12} xs={12} className={classes.catBoxImg}>
                {" "}
                <Box
                  alt="Guest post submission for technology blog"
                  title="Guest post submission for technology blog"
                  component={"img"}
                  className={classes.catBoxImgInner}
                  maxHeight="400px"
                  maxWidth="100%"
                  src={chooseBlogImg}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box className={classes.guestGuidlines} py={10}>
        <Box className={classes.guestGuidCircle}>
          <Box component={"img"} height={250} src={guideCircle} />
        </Box>
        <Container>
          <Box className={classes.marginAuto} maxWidth={960}>
            <HeaderTypography
              className={classes.blogCathead}
              fontSize={40}
              fontWeight={600}
              mb={3}
              mt={0}
              textAlign="center"
              component="h1"
            >
              Guidelines for guest authors
            </HeaderTypography>
          </Box>
          <Box>
            <Grid
              alignItems="center"
              className={classes.blogCatGrid}
              container
              justify="center"
              spacing={2}
            >
              <Grid className={classes.dashBorder} item md={4} sm={12} xs={12}>
                <Box className={classes.blogCatagory}>
                  <Box className={classes.blogCatagoryItem}>
                    <Box
                      alt="Unique content for SMEs"
                      title="Unique content for SMEs"
                      className={classes.blogCatagoryItemImg}
                      component={"img"}
                      src={uniqueContent}
                    ></Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryHead}
                      fontSize="22px"
                      textAlign="left"
                    >
                      Unique content
                    </ParagraphTypography>
                  </Box>
                  <Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryContent}
                      color="#000"
                      fontSize="16px"
                      textAlign="left"
                    >
                      Share original and unpublished content, subject to
                      rigorous review.
                    </ParagraphTypography>
                  </Box>
                </Box>
              </Grid>
              <Grid className={classes.dashBorder} item md={4} sm={12} xs={12}>
                <Box className={classes.blogCatagory}>
                  <Box className={classes.blogCatagoryItem}>
                    <Box
                      className={classes.blogCatagoryItemImg}
                      component={"img"}
                      src={wordLimit}
                      alt="Data supported guest blogs"
                      title="Data supported guest blogs"
                    ></Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryHead}
                      fontSize="22px"
                      textAlign="left"
                    >
                      Word limit
                    </ParagraphTypography>
                  </Box>
                  <Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryContent}
                      color="#000"
                      fontSize="16px"
                      textAlign="left"
                    >
                      Submit a 1000-1500 worded article supported by data and
                      evidence.
                    </ParagraphTypography>
                  </Box>
                </Box>
              </Grid>
              <Grid className={classes.dashBorder} item md={4} sm={12} xs={12}>
                <Box className={classes.blogCatagory}>
                  <Box className={classes.blogCatagoryItem}>
                    <Box
                      className={classes.blogCatagoryItemImg}
                      component={"img"}
                      src={actionTakeaway}
                      alt="Sales tips, sales strategies, and actionable sales insights"
                      title="Sales tips, sales strategies, and actionable sales insights"
                    ></Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryHead}
                      fontSize="22px"
                      textAlign="left"
                    >
                      Actionable takeaways
                    </ParagraphTypography>
                  </Box>
                  <Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryContent}
                      color="#000"
                      fontSize="16px"
                      textAlign="left"
                    >
                      Provide practical advice, tips, and key takeaways.
                    </ParagraphTypography>
                  </Box>
                </Box>
              </Grid>
              <Grid className={classes.dashBorder} item md={4} sm={12} xs={12}>
                <Box className={classes.blogCatagory}>
                  <Box className={classes.blogCatagoryItem}>
                    <Box
                      className={classes.blogCatagoryItemImg}
                      component={"img"}
                      src={seoContent}
                      alt="Guest author blogs"
                      title="Guest author blogs"
                    ></Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryHead}
                      fontSize="22px"
                      textAlign="left"
                    >
                      SEO content
                    </ParagraphTypography>
                  </Box>
                  <Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryContent}
                      color="#000"
                      fontSize="16px"
                      textAlign="left"
                    >
                      Submit keyword-rich and search engine optimised content.
                      Include necessary meta details.
                    </ParagraphTypography>
                  </Box>
                </Box>
              </Grid>
              <Grid className={classes.dashBorder} item md={4} sm={12} xs={12}>
                <Box className={classes.blogCatagory}>
                  <Box className={classes.blogCatagoryItem}>
                    <Box
                      className={classes.blogCatagoryItemImg}
                      component={"img"}
                      src={visualElements}
                      alt="Guest blog guidelines"
                      title="Guest blog guidelines"
                    ></Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryHead}
                      fontSize="22px"
                      textAlign="left"
                    >
                      Visual elements
                    </ParagraphTypography>
                  </Box>
                  <Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryContent}
                      color="#000"
                      fontSize="16px"
                      textAlign="left"
                    >
                      Incorporate images, offer source attribution, and refrain
                      from using copyrighted images.
                    </ParagraphTypography>
                  </Box>
                </Box>
              </Grid>
              <Grid className={classes.dashBorder} item md={4} sm={12} xs={12}>
                <Box className={classes.blogCatagory}>
                  <Box className={classes.blogCatagoryItem}>
                    <Box
                      className={classes.blogCatagoryItemImg}
                      component={"img"}
                      src={unaffiliatedSkill}
                      alt="Unaffiliated links in guest author blogs"
                      title="Unaffiliated links in guest author blogs"
                    ></Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryHead}
                      fontSize="22px"
                      textAlign="left"
                    >
                      Unaffiliated links
                    </ParagraphTypography>
                  </Box>
                  <Box>
                    <ParagraphTypography
                      alignItems="left"
                      className={classes.blogCatagoryContent}
                      color="#000"
                      fontSize="16px"
                      textAlign="left"
                    >
                      No excessive self-promotion. Include 1-2 relevant links
                      and 3-4 interlinks to our related blogs.
                    </ParagraphTypography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.downloadBtn}>
            <Box>
              {" "}
              <ParagraphTypography
                color="#000"
                fontSize="16px"
                fontFamily="'SourceSansPro-Regular', sans-serif"
              >
                Read detailed guidelines{" "}
              </ParagraphTypography>
            </Box>
            <button
              className={classes.downloadBtnInner}
              onClick={downloadPdf}
              type="button"
              style="font-family:'SourceSansPro-Regular', sans-serif"
            >
              here.
            </button>
          </Box>
          <Box className={classes.writeToUsButton}>
            <Button
              color="secondary"
              size="large"
              variant="contained"
              component={Link}
              href="#"
            >
              Write for Us
            </Button>
          </Box>
        </Container>
      </Box>
      <Box className={classes.editorialProcess} py={10}>
        <Box className={classes.guestGuidDots}>
          <Box component={"img"} height={250} src={guideDot} alt="Background" />
        </Box>
        <Container>
          <Box className={classes.marginAuto} maxWidth={960}>
            <HeaderTypography
              fontSize={40}
              mb={3}
              mt={0}
              textAlign="center"
              color="#000"
              component="h3"
            >
              Our editorial process
            </HeaderTypography>
            <ParagraphTypography fontSize={18} textAlign="center" color="#000">
              We value your time and commitment as a guest author. While we
              strive to respond to all authors, if you haven't heard from us in
              two weeks, feel free to explore other blog ideas and submit them
              for consideration. Please be aware that we retain editorial
              control to refine and enhance your content to align with our
              established style.
            </ParagraphTypography>
          </Box>
        </Container>
      </Box>
      <Box className={classes.promotionBoxSection} py={10}>
        <Container>
          <Box className={classes.marginAuto} maxWidth={960}>
            <HeaderTypography
              fontSize={40}
              mb={3}
              mt={0}
              textAlign="center"
              color="#000"
              component="h3"
            >
              Promotion and distribution
            </HeaderTypography>
            <ParagraphTypography fontSize={18} textAlign="center" color="#000">
              We are committed to promoting published guest blogs to our
              dedicated readership through various channels. We also encourage
              you to take proactive steps and promote your blog within your
              network. Here is how you can do it:
            </ParagraphTypography>
          </Box>
          <Box>
            <Grid alignItems="center" container justify="center" spacing={2}>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                className={classes.promotionGrid}
              >
                <Box className={classes.promotionBox}>
                  <Box component={"img"} height="40px" src={tickMark} />
                  <Box>
                    <ParagraphTypography
                      className={classes.promotionBoxContent}
                    >
                      Share on your personal social media profiles.
                    </ParagraphTypography>
                  </Box>
                </Box>
                <Box className={classes.promotionBox}>
                  <Box component={"img"} height="40px" src={tickMark} />
                  <Box>
                    <ParagraphTypography
                      className={classes.promotionBoxContent}
                    >
                      Promote on your brand's official social media platforms.
                    </ParagraphTypography>
                  </Box>
                </Box>
                <Box className={classes.promotionBox}>
                  <Box component={"img"} height="40px" src={tickMark} />
                  <Box>
                    <ParagraphTypography
                      className={classes.promotionBoxContent}
                    >
                      Share the blog link with your existing subscribers through
                      the newsletter.
                    </ParagraphTypography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                className={classes.promotionGrid}
              >
                <Box className={classes.promotionBox}>
                  <Box component={"img"} height="40px" src={tickMark} />
                  <Box>
                    <ParagraphTypography
                      className={classes.promotionBoxContent}
                    >
                      Link the published blog with other relevant blogs you have
                      authored.
                    </ParagraphTypography>
                  </Box>
                </Box>
                <Box className={classes.promotionBox}>
                  <Box component={"img"} height="40px" src={tickMark} />
                  <Box>
                    <ParagraphTypography
                      className={classes.promotionBoxContent}
                    >
                      Share it among your network of industry experts.
                    </ParagraphTypography>
                  </Box>
                </Box>
                <Box className={classes.promotionBox}>
                  <Box component={"img"} height="40px" src={tickMark} />
                  <Box>
                    <ParagraphTypography
                      className={classes.promotionBoxContent}
                    >
                      Maintain attribution and use REL: CANONICAL when
                      cross-posting.
                    </ParagraphTypography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      {/* Book a free demo */}
      <Box className={classes.requestDemoRoot} py={10} textAlign="center">
        <Box alignItems="center" display="flex" justifyContent="center">
          <HeaderTypography
            color="common.white"
            fontSize={35}
            my={0}
            textAlign="center"
            component="h3"
          >
            Share your sales wisdom with a global audiences!
          </HeaderTypography>
        </Box>
        <Box mt={6} textAlign="center">
          <WhiteButton
            color="primary"
            component={Link}
            href="#"
            size="large"
            variant="contained"
          >
            Write for Us
          </WhiteButton>
        </Box>
      </Box>
      {/* footer */}
    </React.Fragment>
  );
};

export default GuestBlog;
